import React from 'react';
import { Box, Button, Divider, Typography } from "@material-ui/core"

export const HomeHeader = ({ year }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      zIndex="1000"
      top="0"
      left="0"
      style={{ backgroundColor: "white", borderBottom: 'solid 4px #f142a9', padding: 10 }}
    >
      <Typography variant="body1" style={{ color: "purple", textAlign: "center" }}>Ahmed ❤️ Valérie</Typography>
      <Divider orientation="vertical" style={{ height: 20, width: 2, marginLeft: 10, marginRight: 10 }}/>
      <Button variant={year === "/2020" ? "contained" : "outlined"} color="primary" href="/2020">2020</Button>
      <Button variant={year === "/2022" ? "contained" : "outlined"} color="primary" href="/2022" style={{ marginLeft: 10 }}>2022</Button>
    </Box>
  )
};