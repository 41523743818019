import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Memory } from './memory/Memory';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { ContentContainer } from './ContentContainer'
import { Box, Button } from '@material-ui/core';
import { HomeHeader } from './components/HomeHeader';

const useStyles = makeStyles(() => ({
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0px'
    },
    content: {
      display: 'flex',
      justifyContent: "center",
      flexWrap: 'wrap',
      maxWidth: '850px'
    }
}));
  
const Header = ({ year }) => {
    const classes = useStyles();

    return (
        <ContentContainer style={{ flexDirection: "column", marginTop: 100 }}>
            <HomeHeader year={year} />
            <Typography variant="h3" style={{ marginBottom: "20px", textAlign: "center" }}>Happy 27th Birthday, Valérie!🤗</Typography>
            <video
                src="/images/video.mp4" width="100%" controls playsInline poster="/images/poster.jpg"
                style={{ alignSelf: "center" }}>
            </video>

            <Divider style={{ margin: "20px 0px", height: "5px" }}/>
            <Typography variant="h3" style={{ marginBottom: "20px", textAlign: "center" }}>I love you because you are...</Typography>
        </ContentContainer>
    )
}
  

export const Home = ({ year }) => {
    return (
        <>
            <Header year={year} />
            <ContentContainer>
                <Memory
                    available="08:00:00"
                    memoryTitle="Loving"
                    memoryPage="/you-are-loving"
                    memoryDescription="You give your friends and family more than you take"
                    image="loving.png"/>
                <Memory
                    available="10:00:00"
                    memoryTitle="Compassionate"
                    memoryPage="/you-are-compassionate"
                    memoryDescription="You have unconditional love for all forms of life"
                    image="compassionate.png"/>
                <Memory
                    available="11:59:59"
                    memoryTitle="Curious"
                    memoryPage="/you-are-curious"
                    memoryDescription="You have a humble curiosity for exploring to understand others"
                    image="curious.jpeg"/>
                <Memory
                    available="14:00:00"
                    memoryTitle="Cheerful"
                    memoryPage="/you-are-cheerful"
                    memoryDescription="You have that cheerful attitude that makes you the cool aunt every kid wants"
                    image="cheerful.png"/>
                <Memory
                    available="16:00:00"
                    memoryTitle="Inclusive"
                    memoryPage="/you-are-inclusive"
                    memoryDescription="You make everyone feel included, no matter their age or background."
                    image="inclusive.jpeg"/>
                <Memory
                    available="18:00:00"
                    memoryTitle="Grateful"
                    memoryPage="/you-are-grateful"
                    memoryDescription="No matter how big of a curveball life throws you, you manage to stay positive"
                    image="grateful.jpeg"/>
                <Memory
                    available="20:00:00"
                    memoryTitle="Hard-Working"
                    memoryPage="/you-are-hardworking"
                    memoryDescription="Your working spirit is unbreakable despite all the challenges you face"
                    image="hardworker.png"/>
            </ContentContainer>
        </>
    );
}