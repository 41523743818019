import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid';
import { TimerLockedContent } from './TimerLocked';
import { TextField } from '@material-ui/core';
import { useCookies } from 'react-cookie'

const useStyles = makeStyles((theme) => ({
    memoryContainer: {
        [theme.breakpoints.up('md')]: {
            margin: '10px',
        },
        [theme.breakpoints.down('md')]: {
            margin: '10px 0px',
        },
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: '0',
        height: '406px',
    },

    memoryImage: {
        position: 'absolute',
        borderRadius: "20px",
        filter: "brightness(0.5)",
        '&:hover': {
            border: 'solid 1px gray'
        }
    },

    memoryContent: {
        '& > h1': {
            color: 'white'
        },
        zIndex: "5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    memoryDescription: {
        color: "#ddd",
        maxWidth: "80%",
        margin: "10px 0px",
        textAlign: "center"
    },

    hint: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: '1rem',
        marginTop: 10,
    },

    textfield: {
        backgroundColor: 'black',
        marginTop: 20,
        padding: 5,
        '& > input': {
            backgroundColor: 'white'
        },
        border: 'solid 1px #f142a9'
    },

    timer: {
        color: "white",
        padding: "4rem",
        zIndex: 5,
        fontSize: "48px",
        backgroundColor: "rgba(0,0,0,0.8)"
    },

    lockedImage: {
        position: 'absolute',
        borderRadius: "20px",
    },
}));

export const Challenge = (props) => {
    const classes = useStyles();

    const [cookies, setCookie, removeCookie] = useCookies([props.cookieKey])

    const onChange = (value) => {
        if (value.target.value === props.answer) {
            setCookie(props.cookieKey, "completed")
        }
    }

    if (cookies[props.cookieKey] === "completed") {
        return (
            <Grid
            item md={5} xs={12} className={classes.memoryContainer}>
                <img src='/images/clue-completed.png' height="406px" width="300px" className={classes.lockedImage}/>
                <Typography  className={classes.timer}>{props.cardPortion}</Typography>
            </Grid>
        )
    }
    

    return (
        <Grid
            item md={5} xs={12} className={classes.memoryContainer}>
            
            <TimerLockedContent unlockTime={props.available}>
                <img src={`/images/${props.image}`} height="406px" width="300px" className={classes.memoryImage}/>
                <Grid item xs={9} className={classes.memoryContent}>
                    <h1>{props.memoryTitle}</h1>
                    {props.hints?.map((hint, idx) => (
                        <Typography key={idx} className={classes.hint}>{hint}</Typography>
                    ))}
                    <TextField className={classes.textfield} placeholder={"enter clue"} color="white" InputProps={{ style: { color: 'white' } }} onChange={onChange} />
                </Grid>
            </TimerLockedContent>
        </Grid>
    )
}