import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0px'
    },
    content: {
      display: 'flex',
      justifyContent: "center",
      flexWrap: 'wrap',
      maxWidth: '850px'
    }
}));

export const ContentContainer = (props) => {

  const classes = useStyles();
  
  return (
    <Grid item xs={12} className={classes.contentContainer}>
      <Grid item lg={8} md={11} xs={12} className={classes.content} style={{ ...props.style}}>
        {props.children}
      </Grid>
    </Grid>
  )
}