import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dat from 'date-and-time';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    lockedImage: {
        position: 'absolute',
        borderRadius: "20px",
    },

    timer: {
        color: "white",
        padding: "1rem",
        zIndex: 5,
        backgroundColor: "rgba(0,0,0,0.4)"
    }
}));

export const TimerLockedContent = (props) => {

    const classes = useStyles();

    dat.plugin(require('date-and-time/plugin/day-of-week'));
    dat.plugin(require('date-and-time/plugin/timespan'));

    const [locked, setLocked] = React.useState(true);
    const [timer, setTimer] = React.useState("23 hours, 59 minutes, 59 seconds");
    let unlock = dat.parse(`Fri Aug 26 2022 ${props.unlockTime} GMT-0500`, 'ddd MMM DD YYYY hh:mm:ss ...');

    React.useEffect(() => {
        setInterval(() => {

            if (!locked) {
                return;
            }

            let difference = dat.timeSpan(unlock, new Date());
            if (parseInt(difference.toSeconds('s')) <= 0) {
                setLocked(false);
            }

            setTimer(difference.toHours('H [hours], m [minutes], s [seconds]'));
        }, 1000);
    }, []);

    if (locked) {
        return (
            <>
                <img src='/images/pink-treasurer-paper.png' height="406px" className={classes.lockedImage}/>
                <Typography className={classes.timer}>{timer}</Typography>
            </>
        );
    } else {
        return (
            <>
                {props.children}
            </>
        );
    }
}