import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid';
import { TimerLockedContent } from './TimerLocked';

const useStyles = makeStyles((theme) => ({
    memoryContainer: {
        [theme.breakpoints.up('md')]: {
            margin: '10px',
        },
        [theme.breakpoints.down('md')]: {
            margin: '10px 0px',
        },
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: '0',
        height: '480px',
    },

    memoryImage: {
        position: 'absolute',
        borderRadius: "20px",
        filter: "brightness(0.5)",
        '&:hover': {
            border: 'solid 1px gray'
        }
    },

    memoryContent: {
        '& > h1': {
            color: 'white'
        },
        zIndex: "5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    memoryDescription: {
        color: "#ddd",
        maxWidth: "80%",
        margin: "10px 0px",
        textAlign: "center"
    }
}));

export const Memory = (props) => {
    const classes = useStyles();

    return (
        <Grid
            item md={5} xs={12} className={classes.memoryContainer}>
            
            <TimerLockedContent unlockTime={props.available}>
                <img src={`/images/${props.image}`} height="480px" className={classes.memoryImage}/>
                <Grid item xs={9} className={classes.memoryContent}>
                    <h1>{props.memoryTitle}</h1>
                    <Typography variant="body1" className={classes.memoryDescription}>{props.memoryDescription}</Typography>
                    <Button variant="contained" color="primary" href={props.memoryPage}>More</Button>
                </Grid>
            </TimerLockedContent>
        </Grid>
    )
}