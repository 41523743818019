import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import { Home } from './Home';
import {
  Details,
  LovingDetails,
  CompassionateDetails,
  CuriousDetails,
  CheerfulDetails,
  InclusiveDetails,
  GratefulDetails,
  HardWorkingDetails
} from './Details';

import App2020 from './2020/App';
import App2022 from './2022/App';
import { ThemeProvider } from '@material-ui/styles';
import { appTheme } from './theme';

function App({ match }) {

  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        <div className="App">
          <Switch>
            <Route path='/2020' component={App2020} />
            <Route path='/2022' component={App2022} />
            <Route path="/you-are-loving">
              <Details image="loving.png" title="Loving" body={<LovingDetails />} />
            </Route>
            <Route path="/you-are-compassionate">
              <Details image="compassionate.png" title="Compassionate" body={<CompassionateDetails />} />
            </Route>
            <Route path="/you-are-curious">
              <Details image="curious.jpeg" title="Curious" body={<CuriousDetails />} />
            </Route>
            <Route path="/you-are-cheerful">
              <Details image="cheerful.png" title="Cheerful" body={<CheerfulDetails />} />
            </Route>
            <Route path="/you-are-inclusive">
              <Details image="inclusive.jpeg" title="Inclusive" body={<InclusiveDetails />} />
            </Route>
            <Route path="/you-are-grateful">
              <Details image="grateful.jpeg" title="Grateful" body={<GratefulDetails />} />
            </Route>
            <Route path="/you-are-hardworking">
              <Details image="hardworker.png" title="Hard-Working" body={<HardWorkingDetails />} />
            </Route>
            <Route path="/">
              <Redirect to="/2022" />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
