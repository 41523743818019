import React from 'react';
import { ContentContainer } from './ContentContainer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { useParams, useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
    detailsImage: {
        [theme.breakpoints.up('md')]: {
            height: "480px",
            width: "50%",
        },
        [theme.breakpoints.down('md')]: {
            height: "360px",
            width: "75%"
        },
        borderRadius: "20px",

    }
}));

export const DetailsBody = (props) => {
    return (
        <Typography variant="body1" style={{ margin: "10px 0px" }}>
            {props.children}
        </Typography>
    );
}

export const LovingDetails = () => {
    return (
        <>
            <DetailsBody>
                You have an extraordinary gift of loving. Your passion for your friends and family (and me :p)
                is pure and hopeful. Your loving attitude has shown me that I can do better, and that love
                can be the perfect antidote to the many sad moments we all encounter in life.
            </DetailsBody>

            <DetailsBody>
                You have shown me that love is not just a chemical reaction in our bodies. Love is a life attitude
                that we can use to build bridges with others so we can connect and help each other. Life is really
                tough at times, especially this year with the whole COVID-19 pandemic. But despite all the harships,
                you have shown me that Love is one of our strongest tools to overcoming adversity and hardship.
            </DetailsBody>
        </>
    )
}

export const CompassionateDetails = () => {
    return (
        <>
            <DetailsBody>
                It is really inspiring to see your attitudes towards animals.
                You amazed me when you decided to adopt a feral cat so you can give her
                a better life (compared to her previous absent owner). You are an amazing
                pet owner, and Coco Chanel is infinitely spoiled and well treated!
            </DetailsBody>

            <DetailsBody>
                On top of that, you are a long-time committed vegan. Your actions show how much
                compassion you have for animals, when many people still believe that animals
                are not worthy of respect and dignity. You inspired me to become vegeterian for 2 months
                last year, and I hope to be able to try again in the future.
            </DetailsBody>
        </>
    )
}

export const CuriousDetails = () => {
    return (
        <>
            <DetailsBody>
                You have been to a lot of countries overseas in europe and asia. Something
                that I admire about you is that your tourist attitude is not purely
                leisure-driven. You understand the importance of exploring foreign cultures and understanding
                why people lead certain lifestyles that you are not used to.
            </DetailsBody>

            <DetailsBody>
                This curiosity of yours, I believe, has made you a wiser and more accepting person.
                Ignorance in the world is often bred from complete disconnect with cultures and communites.
                Many people prefer to stay in their bubble and not learn about and accept other cultures. I hope
                to one day follow in your steps and learn more about the diversity of cultures on earth.
            </DetailsBody>
        </>
    );
}

export const CheerfulDetails = () => {
    return (
        <>
            <DetailsBody>
                You are the cool aunt every kid dreams to have. You take the time and put the effort to
                spend quality time with your niece and nephew. They are very lucky to have an aunt who really
                cares about them. My mom's aunt had a great influence on my childhood (and by extension my adult
                life), so I want you to know that you are performing a critical role with their childhood!
            </DetailsBody>

            <DetailsBody>
                Your commitment to your niece and nephew did not come as a shock to me, though. You are a cheerful
                person that glows with excitement. Conversations with you are delightful and you have a great smile.
                You always manage to flip my frown upside down in a short period of time!
            </DetailsBody>
        </>
    );
}

export const InclusiveDetails = () => {
    return (
        <>
            <DetailsBody>
                I never imagined (not even in my wildest dreams) that i will end up wearing a santa hat while putting
                a star on top of a christmas tree. I always thought that based on my background, that's just
                something I will not experience. I am happy you proved me wrong. You showed me that any activity
                can be inclusive to people from all walks of life.
            </DetailsBody>

            <DetailsBody>
                Our world can benefit from promoting inclusion. To make that happen, we need to lead
                by example and show each other that at the end of the day, we are all humans. From
                the day I met you, your language and behaviours have always been inclusive. You have
                never made me feel like an outsider, and I am grateful for that.
            </DetailsBody>
        </>
    );
}

export const GratefulDetails = () => {
    return (
        <>
            <DetailsBody>
                It is important that we are reminded of our privileges in life. Greed and
                comparison will rob us of any happiness if we do not contain them! I am lucky
                to have you around because you are the queen of gratitude. It is truly impressive
                how aware you are of all the privileges you enjoy.
            </DetailsBody>

            <DetailsBody>
                In a world of social media and constant comparison, gratitude is becoming an inceasingly
                important trait to have. I am so thankful to have you in my life. We are a great team, and
                we guide each other through the next steps of our personal and professional lives! :)
            </DetailsBody>
        </>
    );
}

export const HardWorkingDetails = () => {
    return (
        <>
            <DetailsBody>
                Your hard-working spirit is unmatched. Over the past year, I have watched you
                take on challenge after challenge, often multiple at the same time. You always impressed me
                with how well you managed these challenges. Sometimes you make mistakes, and you always
                made it a point to learn and improve so that you are ready the next time around!
            </DetailsBody>

            <DetailsBody>
                Like me, you sometimes take on a lot of work so you can give back more. On top of your
                salaried work, you volunteer in your spare time to help make an impact in your local community.
                Your choice of spending your spare time to volunteer speaks a lot of about your self-less character.
                Stay awesome!
            </DetailsBody>
        </>
    );
}

export const Details = (props) => {

    const classes = useStyles();

    return (
        <ContentContainer style={{ flexDirection: "column"}}>
            <Button href={"/2022"} color="primary" variant="outlined" style={{ width: "max-content" }}>Back to Home</Button>
            <Divider style={{ margin: "20px 0px" }}/>
            <img className={classes.detailsImage} src={`/images/${props.image}`} />
            <Typography variant="h3" color="primary" style={{ margin: "20px 0px" }}>{props.title}</Typography>
            {props.body}
        </ContentContainer>
    );
}