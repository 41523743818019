import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { Home } from './Home';
import {
  Details,
  LovingDetails,
  CompassionateDetails,
  CuriousDetails,
  CheerfulDetails,
  InclusiveDetails,
  GratefulDetails,
  HardWorkingDetails
} from './Details';

function App({ match }) {
  return (
    <CookiesProvider>
      <Route path={`${match.path}/you-are-loving`}>
        <Details image="loving.png" title="Loving" body={<LovingDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-compassionate`}>
        <Details image="compassionate.png" title="Compassionate" body={<CompassionateDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-curious`}>
        <Details image="curious.jpeg" title="Curious" body={<CuriousDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-cheerful`}>
        <Details image="cheerful.png" title="Cheerful" body={<CheerfulDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-inclusive`}>
        <Details image="inclusive.jpeg" title="Inclusive" body={<InclusiveDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-grateful`}>
        <Details image="grateful.jpeg" title="Grateful" body={<GratefulDetails />} />
      </Route>
      <Route path={`${match.path}/you-are-hardworking`}>
        <Details image="hardworker.png" title="Hard-Working" body={<HardWorkingDetails />} />
      </Route>
      <Route exact path={`${match.path}`}>
        <Home year={match.path} />
      </Route>
    </CookiesProvider>
  );
}

export default App;
