import React from 'react';

import { Challenge } from './challenge/Challenge';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { ContentContainer } from './ContentContainer'
import { Box, Button, Image } from '@material-ui/core';
import { HomeHeader } from '../components/HomeHeader';
import { flash } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const flashAnimation = keyframes`${flash}`;

const FlashyText = styled(Typography)`
  animation: 2s ${flashAnimation};
  animation-iteration-count: 2;
  color: #f142a9
`;

const Header = ({ year }) => {
    return (
        <ContentContainer style={{ flexDirection: "column", minHeight: "100vh", alignItems: 'center' }}>
            <HomeHeader year={year} />
            <Typography>🎉🎉</Typography>
            <FlashyText variant="h3" style={{ marginBottom: "20px", textAlign: "center", fontWeight: '700' }}>Happy 29th Birthday, Valérie!</FlashyText>
            <img src="./images/ch-dance.gif" style={{ maxWidth: 200 }} />
        </ContentContainer>
    )
}

const ChallengeIntroduction = () => {
    return (
        <ContentContainer style={{ flexDirection: "column", minHeight: "100vh", alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h3" color="primary" style={{ alignSelf: 'flex-start' }}>Get ready.</Typography>
            <Typography variant="h4">You are going to be solving some clues today. Some are online and some are treasurer-hunt style. There will be a prize.</Typography>
        </ContentContainer>
    )
}
  

export const Home = ({ year }) => {
    return (
        <>
            <Header year={year} />
            <ChallengeIntroduction />
            <ContentContainer>
                <Challenge
                    available="08:00:00"
                    cookieKey="clue-1"
                    answer="a82js"
                    cardPortion="6006"
                    memoryTitle="Home"
                    hints={['Nutrient rich', 'Essential for life', 'Nature']}
                    image="clue-1-image.jpg"/>
                <Challenge
                    available="11:00:00"
                    cookieKey="clue-2"
                    answer="cryptography"
                    cardPortion="4966"
                    memoryTitle="Internet"
                    hints={["The standard for encryption", "256 bits", "32-bit key"]}
                    image="clue-2-image.jpeg"/>
                <Challenge
                    available="14:00:00"
                    cookieKey="clue-3"
                    answer="painting"
                    cardPortion="4954"
                    memoryTitle="Adversity"
                    hints={["flappybird.io", "Get a score of 6 or more to proceed", "Ahmed will give you the clue"]}
                    image="clue-3-image.jpg"/>
                <Challenge
                    available="17:00:00"
                    cookieKey="clue-4"
                    answer="red"
                    cardPortion="2084"
                    memoryTitle="Real Estate"
                    hints={["Our Neighbourhood", "Cheapest in 10 years", "Dress color of woman in wall painting"]}
                    image="clue-4-image.jpg"/>
                <Challenge
                    available="18:00:00"
                    cookieKey="clue-5"
                    answer="aritzia"
                    cardPortion="500"
                    memoryTitle="You made it"
                    hints={["Your final clue waits you in the mailbox."]}
                    image="clue-image-5.png"/>
            </ContentContainer>
        </>
    );
}